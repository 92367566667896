*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow-x: hidden; */
  }
  .ck-editor__editable {
    min-height: 200px;
  }
  .css-ds07z6 {
    margin-top: 0px !important;
  }
  .find_class_parent {
    display: flex;
    justify-content: center;
    /* margin-top: 10%; */
    width: 90%;
   /* border:"1px solid red", */
   margin-left: auto;
   border-radius: 30px;
   overflow: hidden;
  }
  .find_select {
    color: gray;
    font-size: 18px;
    outline: none;
    background: transparent;
    width: 100%;
    height: 78px;
    background: #fff;
    padding: 10px;
    background-image: url('https://c.superprof.com/style/images/home/v4/book-new-off.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 40px;
    appearance: none;
    position: relative;
    box-shadow: none;
  }
  .location_icon {
    height: 34px;
    width: 50px;
    margin-right: 5px;
    filter: grayscale(100%);
  }
  .search_button {
    min-width: 18% !important;
    height: 75px;
    background: #ff7002 !important;
    color: #fff;
  }
  
 

  
  @media screen and (max-width: 480px) {
    .find_class_parent {
      margin-left: 0;
      width: 100%;
      margin-bottom: 25px;
    }
    .search_button {
      height: 70px;
    }
    .find_select {
      height: 70px;
    }
    
    /* .find_select {
      height: 60px;
    } */
  
  }